// Libs
import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

// Components
import SEO from '../components/seo';

const IndexPage = () => {
  const getRedirectLanguage = () => {
    if (typeof navigator === 'undefined') {
      return 'en';
    }
  
    return navigator && navigator.language === 'pt-BR' ? 'pt-br' : 'en-us';
  };

  useEffect(() => {
    const urlLang = getRedirectLanguage();

    navigate(`/${urlLang}/`, {replace: true});
  }, []);

  return <SEO
    lang='en-US'
    title='Home'
    ogTitle='Manage and Boost Team’s Work, Projects and Tasks | Strytegy'
    description='➤ Manage and Boost Team’s Work, Projects and Tasks 100% Online ✅ ➤ Easily organize workflows and ✚ Start using Strategy as your work management tool!'
    alternativeLang='pt-br'
    alternativeLink='https://www.strytegy.com/en-us/'
    canonicalLink='https://www.strytegy.com/'
  />;
};

export default IndexPage;
